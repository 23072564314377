<template>
    <base-section id="theme-features">
    <base-section-heading title="Lista de Usuarios">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
            <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            class="elevation-1"
            :search="search"
            >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>

                <template v-slot:item.id="{ item }">
                <v-list-item :to="'/profile/'+item.id">
                <a href="#"> {{item.id}}</a>
                </v-list-item>
                </template>
                <template v-slot:item.actions="{ item }">
                <v-btn  text icon 
                    :to="'/user/'+item.id+'/devices'">
                    <v-icon>mdi-cellphone-arrow-down</v-icon>
                </v-btn>
                <v-btn text icon 
                    :to="'/app/'+item.id">
                    <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                </template>
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    </base-section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    metaInfo: { title: 'Users' },
    data: () => ({
        totalUsers: 0,
        users: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'Id',
            align: 'start',
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Role Id', value: 'roleid' },
          { text: 'Active', value: 'active' },
          { text: 'Actions', value: 'actions', align: 'center', },
        ],
        search:'',
    }),
    mounted(){
        this.loadUsers();
    },
    methods:{
        ...mapActions(['getUserList']),
        loadUsers(){
            this.getUserList().then(res => {
                this.users = res.data.data;
                this.totalUsers = res.data.totalRecords;
                this.loading = false;
                //console.log(this.users);
            })
        },
    }
}
</script>

<style>

</style>